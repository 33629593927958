import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../_shared/services/auth.service";
import {PromptService} from "../_shared/services/prompt.service";

@Injectable({
    providedIn: 'root'
})
export class CanManageUsersGuard  {
    constructor(
        private _auth: AuthService,
        private _prompt: PromptService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const user = this._auth.user;
        if (!user) return false;
        if (user.role === 'SuperUser') return true;

        const canManageUsers = user.permissions.some(x => x.permission === 'ManageUsers');
        if (!canManageUsers) this._prompt.error('You do not have permission to manage users', null, true);
        
        return canManageUsers;
    }

}
